import SvgIcon from '@/components/SvgIcon'// svg component

// register globally

export default (Vue) => {
  Vue.component('svg-icon', SvgIcon)

  const req = require.context('../icons/svg', false, /\.svg$/)
  const requireAll = requireContext => requireContext.keys().map(requireContext)
  requireAll(req)
}
