import { Requests } from "@/utils/request";


// 1-登录
export function login(data) {
    return Requests({
      url: '/front/user/login',
      method: 'post',
      data
    })
  }

  // 2-用户注册
  export function Register(data) {
    return Requests({
      url: '/front/user/register',
      method: 'post',
      data
    })
  }
 // 3-发送验证码
 export function CodeSend(params) {
  return Requests({
    url: '/front/user/send/code',
    method: 'get',
    params
  })
}

 // 4-登出操作
 export function Logout(params) {
  return Requests({
    url: '/front/user/logout',
    method: 'get',
    params
  })
}

// 5-查看所有通知消息
export function GetNoticeList(params) {
  return Requests({
    url: '/front/user/notice/all',
    method: 'get',
    params
  })
}

// // 6- 企业-新增
// export function AddCompany(data) {
//   return Requests({
//     url: '/front/company/add',
//     method: 'post',
//     data
//   })
// }

// // 7- 企业-解绑
// export function unbindCompany(params) {
//   return Requests({
//     url: '/front/user/edit',
//     method: 'get',
//     params
//   })
// }

  // 8-天眼查-获取列表
export function getTycCompanyList(params) {
  return Requests({
    url: '/front/company/tyc/search',
    method: 'get',
    params 
  })
}

// 9-RFQ信息- RFQ新增
export function addRFQList(data) {
  return Requests({
    url: '/front/rfq/add',
    method: 'post',
    data 
  })
}

//10- 查询所有的RFQ记录
export function getRFQList(data) {
  return Requests({
    url: '/front/rfq/all',
    method: 'post',
    data 
  })
}
//11- RFQ的用户确认合同
export function confirmContact(params) {
  return Requests({
    url: '/front/rfq/confirm',
    method: 'GET',
    params 
  })
}
//12-取消合同
export function cancelContact(params) {
  return Requests({
    url: '/front/rfq/cancel/contract',
    method: 'GET',
    params 
  })
}
//13-取消RFQ订单
export function cancelOrder(params) {
  return Requests({
    url: '/front/rfq/cancel/rfq',
    method: 'GET',
    params 
  })
}

// 14-RFQ信息- 保存为草稿
export function addDraftList(data) {
  return Requests({
    url: '/front/rfq/draft/add',
    method: 'post',
    data 
  })
}
// 15-RFQ信息- 查询所有草稿
export function getDraftList(data) {
  return Requests({
    url: '/front/rfq/draft/all',
    method: 'post',
    data 
  })
}

//16-草稿里确认询价
export function confirmDraftToOrder(params) {
  return Requests({
    url: '/front/rfq/draft/order',
    method: 'GET',
    params 
  })
}
//17-删除草稿
export function delDraft(params) {
  return Requests({
    url: '/front/rfq/draft/delete',
    method: 'GET',
    params 
  })
}
//18-编辑草稿
export function editDraft(data) {
  return Requests({
    url: '/front/rfq/draft/edit',
    method: 'post',
    data 
  })
}

//18- RFQ相关--RFQ 模版上传 获取七牛云的token
export function getUploadToken(params) {
  return Requests({
    url: '/front/common/upload/token',
    method: 'get',
    params
  })
}

//19-前台用户日志收集
export function addPageLog(params) {
  return Requests({
    url: '/front/user/page/view/add',
    method: 'get',
    params
  })
}

//20-企业增加
export function addCompanyInfo(data) {
  return Requests({
    url: '/front/company/add',
    method: 'post',
    data
  })
}

//21-当前用户的企业信息
export function getOnlineCompanyInfo(params) {
  return Requests({
    url: '/front/user/info',
    method: 'get',
  })
}
//21-企业解绑
export function unbindCompanyInfo(params) {
  return Requests({
    url: '/front/user/edit',
    method: 'get',
    params
  })
}

//22-验证该手机号码/邮箱 是否已经存在
export function checkUser(params) {
  return Requests({
    url: '/front/user/check',
    method: 'get',
    params
  })
}

//23-文件从服务器下载（而不是七牛）
export function downLoadFile(params){
  return Requests({
    url: '/front/rfq/download',
    method: 'get',
    params,
    responseType: "blob",
  })
}


//快封业务：
//  24-快封询价
export function addAssemblyQuotation(data) {
  return Requests({
    url: '/front/quick/package/quotation',
    method: 'post',
    data
  })
}
//  25-快封：确认询价
export function sureAssemblyQuotation(data) {
  return Requests({
    url: '/front/rfq/add',
    method: 'post',
    data
  })
}

// 26、联系销售
export function contactSales(data) {
  return Requests({
    url: '/front/quick/package/contact',
    method: 'post',
    data
  })
}

// 27.重新询价针对所有的RFQ 不止是快封
export function restartRequire(params){
  return Requests({
    url: '/front/rfq/add/again',
    method: 'get',
    params
  })
}

//28。增加企业，搜索销售
export function searchSales(params){
  return Requests({
    url: '/front/backend/user/search',
    method: 'get',
    params
  })
}

//29 更新密码
export function updatePass(data){
  return Requests({
    url: '/front/user/edit/password',
    method: 'post',
    data
  })
}

//30 公司设置：编辑接口
export function updateCompanySetting(data){
  return Requests({
    url: '/front/user/edit/info',
    method: 'post',
    data
  })
}

//31 公司设置：当前用户选择的企业的的sales信息 (针对公司设置的时候)
export function seleInfoByTycID(params){
  return Requests({
    url: '/front/company/info',
    method: 'get',
    params
  })
}
