import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import '@/icons'
import plugin from '@/plugins'
// elementui + normalize.css
import 'normalize.css/normalize.css' // A modern alternative to CSS resets
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import locale from 'element-ui/lib/locale/lang/en' // lang i18n 英文
import zhlocale from 'element-ui/lib/locale/lang/zh-CN' // lang i18n 中文

import common from "./utils/common"; //全局方法
Vue.prototype.common = common;


// set ElementUI lang to EN
Vue.use(ElementUI, { zhlocale })
// 如果想要英文版 element-ui，按如下方式声明
// Vue.use(ElementUI)

Vue.config.productionTip = false
 

//弹出框禁止滑动 (为了阻止弹窗时，背景滚动)
Vue.prototype.stopScroll = function () {
  var mo = function (e) { e.preventDefault() }
  document.body.style.overflow = 'hidden'
  document.addEventListener('touchmove', mo, false)// 禁止页面滑动
 }
  
 //弹出框可以滑动
 Vue.prototype.canScroll = function () {
  var mo = function (e) {
   e.preventDefault()
  }
  document.body.style.overflow = ''// 出现滚动条
  document.removeEventListener('touchmove', mo, false)
 }

new Vue({
  router,
  store,
  plugin,
  render: h => h(App),
}).$mount('#app')
