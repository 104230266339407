import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      name: '首页',
      component: ()=>import('@/views/Home.vue'),
    },
    {
      path: '/404',
      name: '404',
      component: ()=>import('@/views/404.vue'),
      hidden: true,
      meta: { title: '404' ,flag:false }
    },
    {
      path: '/login',
      component: () => import('@/views/user/login.vue'),
      hidden: true,
      meta: { title: '登录' ,flag:false }
    },
    {
      path: '/register',
      component: () => import('@/views/user/register.vue'),
      hidden: true,
      meta: { title: '注册', flag:false   }
    },
    {
      path: '/protocol',
      component: () => import('@/views/user/protocol.vue'),
      hidden: true,
      meta: { title: '服务协议' ,flag:false }
    },
    {
      path: '/privacy',
      component: () => import('@/views/user/privacy.vue'),
      hidden: true,
      meta: { title: '隐私政策', flag:false   }
    },


    // 服务内容
    {
      path: '/services/IT-CAD-services',
      name: 'IT-CAD-Services',
      component: () => import('@/views/services/IT-CAD-services.vue'),
      meta: { title: 'IT/CAD服务', icon: '' }
    },
    {
      path: '/services/silicon-services',
      name: 'siliconServices',
      component: () => import('@/views/services/silicon-services.vue'),
      meta: { title: '流片/IP服务', icon: '' }
    },
    {
      path: '/services/design-services',
      name: 'designServices',
      component: () => import('@/views/services/design-services.vue'),
      meta: { title: '设计服务', icon: '' }
    },
    {
      path: '/services/assembly-services',
      name: 'assemblyServices',
      component: () => import('@/views/services/assembly-services.vue'),
      meta: { title: '封装服务', icon: '' }
    },
    {
      path: '/services/test-services',
      name: 'testServices',
      component: () => import('@/views/services/test-services.vue'),
      meta: { title: '测试服务', icon: '' }
    },
    {
      path: '/services/OcclusalPlan-service',
      name: 'OcclusalPlanService',
      component: () => import('@/views/services/OcclusalPlan-service.vue'),
      meta: { title: '咬合方案服务', icon: '' }
    },

    // 应用领域
    {
      path: '/application/DPU',
      name: 'DPU',
      component: () => import('@/views/application/DPU.vue'),
      meta: { title: 'DPU', icon: '' }
    },

    //我的成长
    {
      path: '/myGrowth/EducationAndTraining',
      name: 'EducationAndTraining',
      component: () => import('@/views/myGrowth/EducationAndTraining.vue'),
      meta: { title: '教育培训', icon: '' }
    },
     //我有需求
     {
      path: '/requirement/apply-RFQ',
      name: 'apply-RFQ',
      component: () => import('@/views/requirement/apply-RFQ.vue'),
      meta: { title: '创建RFQ申请', icon: '' }
    },

    // 完善公司资料
    {
      path: '/user/companySetting',
      name: 'CompanySetting',
      component: () => import('@/views/user/companySetting.vue'),
      meta: { title: '企业设置', icon: '' }
    },
     // 修改密码
     {
      path: '/user/passSetting',
      name: 'passSetting',
      component: () => import('@/views/user/passSetting.vue'),
      meta: { title: '修改密码', icon: '' }
    },
    
    // 消息通知
    {
      path: '/user/message',
      name: 'Message',
      component: () => import('@/views/user/message.vue'),
      meta: { title: '消息通知', icon: '' }
    },


  ]
})
