import { login } from '@/api/user'
import { getToken, removeToken } from '@/utils/auth'
import { Message } from 'element-ui';
import{setToken} from '@/utils/auth'

var phoneReg = /^[1][3,4,5,7,8][0-9]{9}$/;  

const getDefaultState = () => {
  return {
    token: getToken(),
    roles: localStorage.getItem('roles'),
    id: localStorage.getItem('id'),
    Name: localStorage.getItem('name'),
    avatar: localStorage.getItem('avatar')
  }
}

const state = getDefaultState()

const mutations = {
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState())
  },
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_ID: (state, id) => {
    localStorage.setItem('id', id)
    state.id = id
  },
  SET_ROLES: (state, roles) => {
    localStorage.setItem('roles', roles)
    state.roles = roles
  },
  SET_NAME: (state, name) => {
    localStorage.setItem('name', name)
    state.name = name
  },
  SET_AVATAR: (state, avatar) => {
    localStorage.setItem('avatar', avatar)
    state.avatar = avatar
  }
}

const actions = {
  // user login
  login({ commit }, userInfo) {
   
    const comData={
     ...userInfo
    }
    return new Promise((resolve, reject) => {
      login(comData).then(response => {
        
        if(response.code==200){
          const { data } = response
     
          commit('SET_TOKEN', data.token)
          commit('SET_ID', data.userId)
          commit('SET_ROLES', data.companyId)
          commit('SET_NAME', data.userName)
          localStorage.setItem('isNew',data.isNew)
          // 这里也有可能是邮箱，判断如果是手机号就存储
          localStorage.setItem('account',comData.account) 
          if (phoneReg.test(comData.account)) {  
            localStorage.setItem('tel',comData.account) 
          }else{
            localStorage.setItem('tel','')
          
          }
       
        
          // commit('SET_AVATAR', data.headImage)
          setToken(data.token)
        }
       
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  // user logout
  logout({ commit, state }) {
    return new Promise((resolve, reject) => {
      removeToken()
      localStorage.clear();
      commit('RESET_STATE')
      resolve()
    })
  },

  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      commit('RESET_STATE')
      resolve()
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

