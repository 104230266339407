/**
 * Created by wumengting on 23/10/18.
 */

/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path)
}


//判断是手机端还是pc
export function isPhone(){
// 获取用户代理字符串
const userAgent = navigator.userAgent;
const mobileKeywords = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
if (mobileKeywords.test(userAgent)) {
 return 'phone';
} else {
 return 'pc';
}
}

/**
 * @param {string} str
 * @returns {Boolean}
 */

// 公司名称符合天眼查
export const validateCompanyName = (rule, value, callback) => {
  value=value.replace(/^\s+/,'').replace(/\s+$/,'');
  if (value === '') {
    callback(new Error('请输入企业名称'));

  }else if(value.length<2){
    callback(new Error('请输入关键字在2位以上'));
  }else{
    callback()
  }
};


//ok
export const validatePhone = (rule, value, callback) => {
  var reg =new RegExp(/^1[3456789]\d{9}$/);
  if (value === '') {
    callback(new Error('请输入手机号码'));
  } else if (!reg.test(value)) {
    callback(new Error('请输入正确的手机号码'));
  } else {
    callback();
  }
};

export const validateEmail = (rule, value, callback) => {
  var reg = /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/;
  if (!value) {
    callback(new Error('请输入邮箱账号'))
  } else if (value.search(reg)==-1) {
    callback(new Error('请输入正确格式的邮箱账号！'))
  } else {
    callback()
  }
  //这两个都可以；
  // var strRegex = /^(\w-*\.*)+@(\w-?)+(\.\w{2,})+$/;
  // if(!strRegex.test(value)){
  //   callback(new Error('请输入正确格式的邮箱账号！'))
  // }else{
  //   callback()
  // }
}

//ok
export const validateUserName = (rule, value, callback) => {
  value=value.replace(/^\s+/,'').replace(/\s+$/,'');
  if (value === '') {
    callback(new Error('请输入用户名'));
  }else if(value.length>10){
    callback(new Error('用户名字符在10位以内！'));
  }else{
    callback()
  }
};

//ok 
export const validatePass = (rule, value, callback) => {
  //必须包含大小写字母、数字、特殊字符长度6-20位之间
  var regex = new RegExp("(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{6,20}");
  if (value === "") {
     callback(new Error("请输入密码"));
  } else if (value.length < 6 || value.length > 20) {
    callback(new Error("请输入6~20位密码"));
  } else if (!regex.test(value)) {
    callback(new Error("密码须由英文，特殊字符，数字组成！"));
  } else {
    callback();
  };
}
//ok 
export const validateNull = (rule, value, callback) => {
  if (!`${value}`.trim()) {
    callback(new Error(rule.mes))
  } else {
    callback()
  }
}

//ok 
export const validatePhoneCode = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('请输入验证码'));
  }else{
    callback();
  }
};
// export const validateEmailCode = (rule, value, callback) => {
//   if (value === '') {
//     callback(new Error('请输入邮箱验证码'));
//   }else{
//     callback();
//   }
// };
export const validateImgCode = (rule, value, callback) => {
    if (value === '') {
      callback(new Error('请输入图片验证码'));
    } else {
      callback();
    
    }
    
  };




export const validateIp = (rule, value, callback) => {
  var reg = new RegExp(/^[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}$/)
  if (!reg.test(value)) {
    callback(new Error(rule.mes))
  } else {
    callback()
  }
}

export const validateNum = (rule, value, callback) => {
  var reg = new RegExp(/^[1-9][0-9]{0,}$/)
  if (!reg.test(value)) {
    callback(new Error(rule.mes))
  } else {
    callback()
  }
}








// export const validateUrl = (rule, value, callback) => {
//   var reg = new RegExp(`^((https|http|ftp|rtsp|mms)?://)` +
//     `?(([0-9a-z_!~*'().&=+$%-]+: )?[0-9a-z_!~*'().&=+$%-]+@)?` +
//     `(([0-9]{1,3}.){3}[0-9]{1,3}` +
//     `|` +
//     `([0-9a-z_!~*'()-]+.)*` +
//     `([0-9a-z][0-9a-z-]{0,61})?[0-9a-z].` +
//     `[a-z]{2,6})` +
//     `(:[0-9]{1,4})?` +
//     `((/?)|` +
//     `(/[0-9a-z_!~*'().;?:@&=+$,%#-]+)+/?)$`
//   )
//   if (!`${value}`.trim()) {
//     callback(new Error(rule.mes))
//   } else if (!reg.test(value)) {
//     callback(new Error('请输入正确格式地址'))
//   } else {
//     callback()
//   }
// }
