import filter from '@/plugins/filter'
import svgIcon from '@/plugins/svgIcon'

export default {
  install(Vue) {
    filter(Vue)
    svgIcon(Vue)
    Vue.prototype.$inputError = function(that, message) {
      that.validateState = 'error'
      that.validateMessage = message
    }
  }
}
