import axios from 'axios'
import { Message } from 'element-ui'
import store from '@/store'
import router from '@/router'


// 管理端请求
const Requests = axios.create({
  // baseURL: process.env.VUE_APP_SERVER_URL,
  baseURL: process.env.VUE_APP_IS_AUTOIP === 'no' ? process.env.VUE_APP_SERVER_URL : `${window.location.origin}/api/v1.0`
  // timeout: 3000
})

// 管理端请求拦截器
Requests.interceptors.request.use(
  config => {
    if (store.getters.token) {
      config.headers['Authorization'] = store.getters.token
    }
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// 管理端响应拦截器
Requests.interceptors.response.use(
  response => {
    const res = response.data;
    if (res.code !== 200) {
      console.log('管理端响应拦截---')
      // Message({
      //   message: response.data.message || 'Error',
      //   type: 'error',
      //   duration: 2 * 1000
      // })
 
    }
    return res
  },
  async error => {
    console.log('interceptors  错误：' ); // for debug
    // console.log(error.response)
    let code= error.response.data.code;
    let url=error.response.config.url;
    if (error.response && error.response.data && code ) {
   
      if(code=='401'){
        //非法请求-- token过期/或账户在别的地方登录。
         await store.dispatch('user/logout')
         router.push(`/login`)
        }else if(code=='403'){
          Message({
            message: '出于安全原因您的账户已被暂时锁定！',
            type: 'error',
            duration: 5 * 1000,
            offset: 60
          })
         
        }else{
            if(url=='/front/user/login'|| url== '/front/user/register'){ //如果是登录接口
              console.log(error.response.data.error.message)
            }else{
              //否则弹出提示
              Message({
                message: error.response.data.error.message,
                type: 'error',
                duration: 5 * 1000,
                offset: 60
              })

            }

        
          
        }

    }
     
    
    
    return Promise.reject(error.response || error)
  }
)

export { Requests }
