export default (Vue) => {
  Vue.filter('statusFilter', function(val) {
    if (val === 1) {
      return '待传输'
    } else if (val === 2) {
      return '传输中'
    } else if (val === 3) {
      return '传输成功'
    } else if (val === 4) {
      return '传输异常'
    } else if (val === 5) {
      return '文件校验不通过'
    } else {
      return ''
    }
  })

  Vue.filter('approvalStatusFilter', function(val) {
    if (val === 4) {
      return '已拒绝'
    } else if (val === 1) {
      return '待审核'
    } else if (val === 2) {
      return '审核中'
    } else if (val === 3) {
      return '已审核'
    } else {
      return ''
    }
  })

  Vue.filter('upDataSize', function(val) {
    if (val === 0) return '0'
    if (!val) return ''
    const filesize = Number(val)
    if (filesize >= 1024) {
      const filesize0 = Number(filesize) / 1024 // KB
      if (filesize0 > 1024) {
        const filesize1 = Number((filesize0 / 1024)) // MB
        if (filesize1 > 1024) {
          const filesize2 = Number(filesize1) / 1024 // G
          return filesize2.toFixed(2) + 'G'
        } else {
          return filesize1.toFixed(2) + 'MB'
        }
      } else {
        return filesize0.toFixed(2) + 'KB'
      }
    } else {
      return filesize + 'B'
    }
  })
}
