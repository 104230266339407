<template>
  <div class="header">
    <div class="middle_content">
      <router-link to="/" >
        <div class="logo">
       <img src="@/assets/logo.png" alt="" >
      </div>
 </router-link>
    
      <div class="main_title">
        <el-menu 
        :default-active="$route.name" 
        class="el-menu-demo" 
        mode="horizontal"
         @select="handleSelect" 
          router
          background-color="#fff"
          text-color="#333"
           active-text-color="#0084ff"
            style="flex:1">
          <el-menu-item index="/">首页</el-menu-item>

          <el-submenu index="/services"  >
            <template slot="title">服务内容</template>
            <template  v-for="item in servicesList"  >
              <el-menu-item  :index="item.src "  >
                    <template slot="title">
                      {{ item.name }}
                      </template>
                    </el-menu-item>
            </template>
          
          </el-submenu>

          <el-submenu index="/application">
            <template slot="title">应用领域</template>
            <el-menu-item index="/application/DPU">DPU</el-menu-item>
          </el-submenu>

          <el-submenu index="/myGrowth">
            <template slot="title">我的成长</template>
            <!-- <el-menu-item index="/myGrowth/EducationAndTraining">教育培训服务</el-menu-item> -->
            <el-menu-item index="E">E课网</el-menu-item>
            <!-- <el-menu-item index="J">半导体行业观察 </el-menu-item> -->
          </el-submenu>

          <el-submenu index="/requirement">
            <template slot="title">我有需求</template>
            <el-menu-item v-if="!token" index="/login">创建RFQ申请</el-menu-item>
            <el-menu-item v-else index="/requirement/apply-RFQ">创建RFQ申请</el-menu-item>
           
          </el-submenu>
        </el-menu>
      </div>

      <!-- 账号登录 -->
      <div class="account_Right">
       
      
        <el-dropdown trigger="click" @command="handleCommand" v-if="token!==''&& token!==undefined">
          <div class="account_Name"> {{ account_Name }} </div>
          <el-dropdown-menu slot="dropdown" class="lang-select-dropdown">
            <router-link to="/user/passSetting">
              <el-dropdown-item>修改密码</el-dropdown-item>
            </router-link>

            <router-link to="/user/companySetting">
              <el-dropdown-item>企业设置</el-dropdown-item>
            </router-link>
         
            <el-dropdown-item command="logout">退出</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>

        <div class="account_News" @click="toMessage" v-if="token!==''&& token!==undefined"><svg-icon icon-class="dashboard2" /></div>
        <div class="account_Login" @click="toLogin"  v-if="!token">登录</div>

      </div>
    </div>


    <div class="submitRequire requireNeeds" @click="toRFQ"> <img src="@/assets/sendRFQMessage.png" alt="" /> 我有需求</div>
    <div class="submitRequire registerWelfare" > <img src="@/assets/registerWelfare.png" alt="" /> 扫码领取注册福利</div>
  </div>
</template>
  
<script>
import { mapGetters } from 'vuex'
import{ Logout ,addPageLog} from '@/api/user'
import router from '@/router';
export default {
    name: 'Top',
    data() {
        return {
            pageName: '',
            account_Name: '',
            servicesList: [
                { src: '/services/IT-CAD-services', name: 'IT/CAD服务' },
                { src: '/services/silicon-services', name: '流片/IP服务' },
                { src: '/services/design-services', name: '设计服务' },
                { src: '/services/assembly-services', name: '封装服务' },
                { src: '/services/test-services', name: '测试服务' },
                { src: '/myGrowth/EducationAndTraining', name: '教育培训服务' },
                // { src: '/services/OcclusalPlan-service', name: '咬合方案服务' },
            ]
        };
    },
    computed: {
        ...mapGetters([
            'token',
        ])
    },
    mounted() {
        const localStorageName = localStorage.getItem('name');
        this.account_Name = !localStorageName ? '用户' : localStorageName.substring(0, 1).toUpperCase();
    },
    methods: {
        toLogin() {
          sessionStorage.setItem('localPage', '1') //表示点击登录页面时保存起来，表示是从本域名下的页面跳转的。
            this.$router.push(`/login`);
        },
        toMessage() {
            this.$router.push(`/user/message`);
        },
        toRFQ(){
           this.$router.push(`/requirement/apply-RFQ`);
            document.documentElement.scrollTop = 10;
         
        },
        toCompanySetting() {
            this.$router.push(`/user/companySetting`);
        },
        handleCommand(command) {
            if (command === 'logout') {
                // 调接口的退出
                Logout().then((res) => {
                    if (res.data) {
                        this.$store.dispatch('user/logout', this.server);
                        this.$router.push(`/login`);
                    }
                }).catch(error => {
                    console.log(error);
                });
            }
        },
        handleSelect(key, keyPath) {
            // console.log(key);
            if (keyPath[0] === '/services') {
                this.servicesList.forEach((item, index, arr) => {
                    if (key == item.src) {
                        this.pageName = item.name;
                    }
                });
            }
            else if (key === '/') {
                this.pageName = '首页';
            }
            else if (key === '/application/DPU') {
                this.pageName = 'DPU页面';
            }
            else if (key === 'E') {
                this.pageName = 'E课网';
                window.open("https://www.eecourse.com/", "_blank");
                setTimeout(() => {
                    this.$router.push(`/`);
                }, 100);
            }
            else if (key === '/requirement/apply-RFQ') {
                this.pageName = '创建RFQ申请';
                // window.open("http://www.semiinsights.com/", "_blank");
            }
            else if (key === '/login') {
                //说明没有登录情况，那么给她提示：请先去登录
                this.pageName = '登录';
                //把登录前的RFQ页面路径保存，登录完成之后直接跳转到该页面
                sessionStorage.setItem('beforeLoginPage', '/requirement/apply-RFQ')
            }
            this.addPageNameLog();
        },
        //接口调取
        addPageNameLog() {
            const params = {
                pageName: this.pageName
            };
            addPageLog(params).then((res) => {
                if (res.data) {
                    // console.log('收集日志成功！');
                }
            }).catch(error => {
                console.log(error);
            });
        }
    },
    components: { router }
}
</script>
  
 
<style lang="scss" scoped>
@import '@/scss/common.scss';

.lang-select-dropdown {
  // position: absolute !important;
  // top: 0px !important;
  // left: 0px !important;
}
// .el-popper[x-placement^=bottom] .popper__arrow{
//   left: 83.5px!important;
// }
.el-popper[x-placement^=bottom] .popper__arrow::after{
  margin-left: -63px!important;
}

.header {
  @include width_height(100%, 62px);
  min-width: 1200px;
  @include postion_absolute_top(0, 0, 0);
  @include margin_text_center;
  box-shadow: 0px 4px 6px 0px #e8ebf3;
  color: $messageTitle;
  font-weight: 400;

  .logo {
    @include width_height(130px, 40px);
    float: left;
    margin-top: 10px;

    img {
      @include img_style;
    }
  }

  .middle_content {
    @include width_height(1200px, 62px);
    margin: 0 auto;
  }

  .main_title {
    width: 73%;
    float: left;

    .el-menu.el-menu--horizontal {
      @include flex_display;
      border-bottom: none;
    }

    li {
      padding: 0 10px;
    }
  }

  .account_Right {
    @include width_height(9%, 50px);
    @include flex_space_around;
    cursor: pointer;
  }

  .account_News {
    color: $titleColor;

    .svg-icon {
      @include width_height(35px, 35px);
    }
  }

  .account_Name {
    @include width_height(38px, 38px);
    border-radius: 30px;
    line-height: 38px;
    color: #FFFFFF;
    font-weight: bold;
    margin-top: 10px;
    font-size: 22px;
    background-color: #1e4d96;
  }

  .account_Login {
    @include width_height(60px, 30px);
    border: 1px solid grey;
    float: left;
    font-size: 13px;
    margin-top: 10px;
    line-height: 30px;
    background: #1e4d96;
    font-size: 14px;
    color:#FFFFFF;

  }
}


::v-deep .el-dropdown-menu__item {
  width: 130px !important;
  padding:0 20px!important;
}

//RFQ软连接
.submitRequire{
  position: fixed;
    /* display: flex; */
    cursor: pointer;
    margin: auto;
    border-radius: 16px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1000000000;
    font-size:14px;
    cursor: pointer;
}
.requireNeeds{
  right: 40px;
  width: 65px;
  height: 70px;
  line-height:20px;

  bottom: calc(30% - 33px);
}

.registerWelfare{
  bottom: calc(30% + 60px);
    width: 112px;
    line-height: 30px;
    right: 20px;
}


</style>
  