<template>
  <div id="app"  :class=" isLoginPage ? 'zoomerScreen' :isPhone()=='phone' ? 'phone_min_width':''" >

    <!-- 头部导航栏 -->
    <Top v-if="this.$route.meta.flag != false"></Top>
    <!-- <keep-alive exclude="searchResult">
      <router-view :key="$route.fullPath"/>
    </keep-alive> -->
    <router-view >

    </router-view>
    <Footer v-if="this.$route.meta.flag != false"></Footer>
   
  </div>
</template>

<script>
import Top from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'


export default {
  name: 'App',
  components: { Top, Footer },
  data() {
    return {
     
    }
  },
  computed: {
    isLoginPage() {
        const path=this.$route.path;
        return path.includes('login') || path.includes('register') ||path.includes('protocol') ||path.includes('privacy');
    },
  },
  mounted() {
    // 监听浏览器的返回按钮
    // if (window.history && window.history.pushState) {
    //   history.pushState(null, null, document.URL);
    //   window.addEventListener("popstate", this.onCloseModal, false);
    // }
  },
  // 组件销毁阶段，解除监听返回按钮的事件
  // destroyed() {
  //   window.removeEventListener("popstate", this.onCloseModal, false);
  // },

  methods: {
    isPhone(){
    // 获取用户代理字符串
    const userAgent = navigator.userAgent;
    const mobileKeywords = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
    if (mobileKeywords.test(userAgent)) {
    return 'phone';
    } else {
    return 'pc';
    }
}
    // 监听返回按钮的处理函数
    // onCloseModal() {
    //   // 获取当前页面的url地址
    //   const roster_url = window.location.href
    //   // 判断当前如果是登录/注册页面，上一页面要求回到历史页。
    //   const flag_login = roster_url.indexOf('login')
    //   const flag_register = roster_url.indexOf('register')
    //   //如果是登录注册页面进去的，回到原来的页面。
    //   if (flag_login !== -1 || flag_register !== -1) {
    //     this.$router.go(-1)
    //   }

    // },

  }
}
</script>

<style>
@import '@/scss/common.scss';

#app {
  font-family: PingFangSC, PingFangSC-Regular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  width: 100%;
  height: 100%;
  min-width: 1200px; 

}

/* 移动端登录注册 */
@media (max-width: 800px){
  #app {
    height: 100vh;
    width: 100vw;
   
}
}
.phone_min_width{
  /* min-width: 100vw!important; */
  zoom: 0.85;
}



.zoomerScreen {
  min-width: 100vw!important;
  
}

.container {
  width: 100%;
  margin-top: 64px !important;
  overflow-x: hidden;
}

.atooltip {
  background: rgba(0, 0, 0, 0.70) !important;

}
.atooltipShow{
  border: 1px solid #a1a4ab!important;
}

</style>
