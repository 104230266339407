<template>
    <div>
        <div class="footer" :style="{'position':relaShow ?'':'fixed'}" >
            <div >留下您的需求，我们会第一时间与您联系 </div>
            <!-- <div >在线客服</div> -->
            <span style="  margin: 0 16% 23px 50px;" >021-51137998 info@mooreelite.com  </span>
        </div>
        <ul class="footer_main">
            <li >
                <h2>摩尔精英集团</h2>
                <div class="linkWe">联系我们</div>
                <div v-for="(item,index) in linkWeList " :key="index"  class="linkWe_first">
                    <div class="icon">
                        <img :src=item.iconHref alt="">
                    </div>
			        <span><a :href=item.titleDetail target="_blank">{{ item.title }}</a></span>
                </div>

            </li>
            <li >
                <div class="area" v-for="(item ,index) in addressList" v-if="item.id<5" :key="index">
                    <div class="city">{{item.city}}</div>
                    <div>{{ item.address }}</div>
                 </div>
            </li>
            <li >
                <div class="area" v-for="(item ,index) in addressList" v-if="item.id>4" :key="index" >
                    <div class="city">{{item.city}}</div>
                    <div>{{ item.address }}</div>
                </div>
            </li>
        </ul>
        <div class="footer_foot" >
            <div>友情链接: <a href="https://www.eecourse.com/" target="_blank">E课网~专业集成电路IC职业教育平台</a> </div>
            <div>Copyright©2023 摩尔精英集成电路产业发展（合肥）有限公司 &nbsp; &nbsp;  MooreElite All Rights Reserved</div>
            
            <div style="width: 400px; height:30px; margin-top: -20px;"> 
                <span><img src="@/assets/police.png" alt=""  class="policeClass"/>
                <a href="https://beian.mps.gov.cn/#/query/webSearch?code=31011502400765" rel="noreferrer" target="_blank">沪公网安备31011502400765号</a></span>
               |<span><a href="https://beian.miit.gov.cn" target="_blank"> 沪ICP备14012158号-1</a></span>
                
            </div>
        </div>


</div>
</template>
  
<script>
export default {
    name: 'Footer',
    data() {
    return {
        relaShow:true,
        linkWeList:[
            {id:'1',title:'info@mooreelite.com',titleDetail:'mailto:info@mooreelite.com',iconHref:'https://www.mooreelite.com/wp-content/uploads/2020/05/mail_img.png'},
            {id:'2',title:'021-51137998',titleDetail:'tel:021-51137998?utm_source=direct&amp;',iconHref:'https://www.mooreelite.com/wp-content/uploads/2020/05/phone_img.png'},

        ],
        // address
        addressList:[
            {id:'1',city:'上海(总部)',address:'上海市浦东新区金科路2889号长泰商业广场A栋3楼'},
            {id:'2',city:'北京',address:'北京市海淀区丹棱街18号创富大厦18层'},
            {id:'3',city:'深圳',address:'广东省深圳市南山区软件产业基地5栋E座2层'},
            {id:'4',city:'成都',address:'四川省成都市武侯区高朋大道3号A座900室'},
            {id:'5',city:'无锡SiP先进封测中心',address:'江苏省无锡市惠山经济开发区生科路12号'},
            {id:'6',city:'重庆先进封装创新中心',address:'重庆市渝北区仙桃数据谷中路105号指环王大厦B栋5楼'},
            {id:'7',city:'合肥快速封装工程中心',address:'安徽省合肥市高新区创新大道106号明珠产业园5幢1层C区'}
        ]
    }
},
mounted(){
// window.addEventListener('scroll',this.fixedFooter)
},
methods:{
    fixedFooter(){
        // var scrollTop=window.pageYOffset || document.documentElement.scrollTop|| document.body.scrollTop; //滚动条高度
        // var windHeight=document.documentElement.clientHeight; //当前窗口高
        // console.log((scrollTop-windHeight)/windHeight)
        //     this.relaShow= scrollTop- windHeight > windHeight*1.38? true:false;
    }
}

}
</script>
  
 
<style lang="scss"  scoped>
@import '@/scss/common.scss';
.policeClass{
    width: 18px;
    height: 18px;
    position: relative;
    left: 14px;
    top: 14px;
}

ul li:nth-child(1){color:#ffffff;}
ul li:nth-child(3){margin-top:-40px;}
.footer {
    @include width_height(100%, 80px);
    @include postion_relative_footer ;
    @include margin_text_center;
     background: #001529;
    line-height: 80px;
    font-size:16px;
    color: #cfd1d7;
    display: flex;
    justify-content: flex-end;
    z-index: 9;

    div:nth-child(2){
        @include width_height(88px, 32px);
        background: #1677ff;
        border-radius: 4px;
        color: #ffffff;
        font-weight: 400;
        margin-top: 13px;
        line-height: 32px;
        margin: 23px 16% 23px 50px;
       
  }
}



.left_link {
    float: left; 
}
// 底部
.footer_foot{
    @include flex_display;
    flex-wrap: wrap;
    margin: 0 auto;
    text-align: center;
    min-width:1200px;
    height:90px;
    background: #232534;
    color:#ffffff;
    border-top:1px solid #282b39;
    font-size:13px;
    color: #a1b1c1;
   
    div{
        width: 100%;
    }
}

//主要内容
.footer_main{
    @include flex_display;
    justify-content: space-evenly!important;
    min-width:1200px;
    height:375px;
    background: #232534;
  

    .area{
        color:#ffffff;
        margin-bottom: 20px;
        div:nth-child(2){
            font-size:14px;
            line-height: 30px;
            width: 300px;
    }
    
   
}
   

    .city{
        font-size: 16px;
        color: #40A8D3;
        letter-spacing: 0.95px;
    }

    .linkWe{
        font-size: 17px;
        margin-bottom: 16px;
        font-weight: 600;
    }
    .linkWe_first{
        margin: 10px 0 20px 0;
    }
    .icon{
        width:24px;
        float: left;
        margin-right: 10px;
        img{
            @include width_height(100%, 100%);
        }
    }
}

h2{
    margin: -30px 0 110px 0;
}




</style>
  